import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/Seo"
import styles from "./Index.module.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className={styles.title}>Stylish Method</h1>
    <section>
      <p>
        Currently the site is down for maintenance whilst we launch a new
        version!
      </p>
      <p>Check back soon!</p>
    </section>
  </Layout>
)

export default IndexPage
